import * as React from "react";
import { FunctionComponent } from "react";
import { Heading, Page } from "../components";

const RulesAndRegulation: FunctionComponent = (): JSX.Element => (
  <Page title="Rules and Regulations" layout="default">
    <div className="tile--two-thirds">
      <Heading level={1} style={{ textAlign: "center" }}>
        Rules and Regulations
      </Heading>
      <div className="tile info-section">
        <p>
          {`RULES AND REGULATIONS – Hawaiiana Management Company, Ltd. These Rules
          are intended to explain the obligations of Users of the Services, as
          to their conduct while using the Services. Failure of any User to
          comply with these Rules may be a breach of the may result in, among
          other things, the termination of any or all of the Services, the
          denial of access to one or more of the Services, the refusal to post
          or transmit, or the alteration or removal of material from a website
          or other area. The Rules are subject to change as provided in the
          Terms of Service.`}
        </p>
        <ol>
          <li>
            {`1. Terms of Service. Users shall comply with the Terms of Service
            posted on and for this website.`}
          </li>
          <li>
            {`2. No Spam. Users shall not upload, send or post unsolicited or
            unauthorized junk mail, "spam," advertising, promotions, chain
            letters, or any other form of solicitation, except postings on an
            association's web site.`}
          </li>
          <li>
            {`3. Unlawful Use. Users shall not upload, transmit or post any
            material, or engage in any other use of the Services, that violates
            any law, rule or regulation, infringes any other person's rights,
            including, without limitation, any intellectual property or privacy
            rights, or otherwise could impose civil or criminal liability. We
            reserve the right to notify any governmental entity, law enforcement
            authority, or any other party that we deem appropriate in our sole
            discretion, of any such activity. Uploading, posting or transmitting
            any content that infringes any patent, trademark, trade secret,
            copyright, publicity or proprietary right of any person or entity
            will be grounds for immediate termination of the Services or other
            corrective action.`}
          </li>
          <li>
            {`4. Defamatory or Abusive Material. Users shall not send or post
            defamatory, obscene, profane, vulgar, threatening, offensive,
            abusive, inaccurate or illegal material. Users shall not "stalk" or
            harass another.`}
          </li>
          <li>
            {`5. Destructive Acts. Users shall not distribute any Internet
            viruses, trojan horses, time bombs, cancelbots, worms, or other
            programming routines or engage in other destructive activities that
            are designed to damage, interfere with, or intercept data or the
            operation of computers, networks or other equipment or systems.
            Users shall not access, or attempt to access, another person's or
            entity's accounts, web sites, networks, servers, equipment or
            systems without proper authorization to do so, or attempt to disrupt
            or interfere with the Services in any manner.`}
          </li>
          <li>
            {`6. Use and Data Storage. We may establish and change from time to
            time general practices and limits on the use of the Services, the
            amount of central processing unit (CPU) processing, bandwidth and
            disk usage, and levels of activity. We will use commercially
            reasonable efforts to give you notice of these practices and limits.
            Violation of these practices and limits will be considered a
            violation of these Rules.`}
          </li>
          <li>
            {`7. Other Activities. Users shall not engage in any other activity
            that we determine in our sole discretion may be harmful to other
            Users or the Services.`}
          </li>
        </ol>
      </div>
    </div>
  </Page>
);

export default RulesAndRegulation;
